
.home-container{
    background-color: rgb(225, 243, 243);
    width: 100vw;
    height: 88vh;
    padding: 0;margin: 0;
}

.main{
   padding-top: 100px;
   padding-left: 100px;
    font-size: 60px;
    font-weight: 700;
   
}
.sub{
    margin-left: 100px;
    width: 750px;
    font-size: 20px;
}
.sub p{
    margin: 15px 0px;
}
.main2{
    color: rgb(18, 127, 137);
}
.click{
    display: flex;
    flex-direction: column;
    position: relative;
    bottom: 200px;left: 1000px;
}
.btn{
    width: 300px;
    height: 30px;
    border: 2px solid black;
    border-radius: 10px;
    margin: 20px;
    font-size: 18px;
    font-weight: 600;
    background:linear-gradient(rgb(40, 191, 205),rgb(22, 162, 174)) ;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    color: black;
}
.btn:hover{
    background: rgb(148, 231, 244);
}
