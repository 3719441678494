.s-container{
    width: 100vw;
    height: 100vh;
    background-color:rgb(225, 243, 243) ;
}
.search{
    width: 500px;
    height: 40px;
    margin-top: 50px;
    margin-left: 480px;
    border: 2px solid black;
    border-radius: 10px;
}
.s-container p{
    font-size: 25px;
    font-weight: 500;
    text-align: center;
    margin-top: 30px;
}