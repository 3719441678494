.t-container{
    margin-top: 30px;
    width: 100vw;
    height: 70vh;
   
}
/* .cells{
    margin: 10px 100px;
    padding: 30px;
    text-align: center;
    
} */
table{
    position: relative;
    z-index: 2;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    width: 60%;
    height: 500px;
    text-align: center;
    background-color: white;
}
th{
    background-color: rgb(22, 162, 174);
}
td{
    background-color: rgb(172, 237, 226);
}
td:hover{
  background-color: rgb(251, 220, 180);
  cursor: pointer;
}
    
